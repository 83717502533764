<template>
  <section id="technology">
    <div class="w">

    <heading title="技术优势" subtitle="technology advantage"></heading>
      <!-- 优势列表 -->
      <div class="feature">
        <div class="feature-item">
          <div class="icon">
            <img src="@/assets/img/technology/icon1.png" alt="" />
          </div>
          <div class="down-arrow"></div>
          <div class="area">100%响应</div>
        </div>
        <div class="feature-item">
          <div class="icon">
            <img src="@/assets/img/technology/icon2.png" alt="" />
          </div>
          <div class="down-arrow"></div>
          <div class="area">页面简洁</div>
        </div>
        <div class="feature-item">
          <div class="icon">
            <img src="@/assets/img/technology/icon3.png" alt="" />
          </div>
          <div class="down-arrow"></div>
          <div class="area">独特的设计</div>
        </div>
        <div class="feature-item last">
          <div class="icon">
            <img src="@/assets/img/technology/icon4.png" alt="" />
          </div>
          <div class="down-arrow"></div>
          <div class="area">多端的体验</div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "FxTechnology",
};
</script>

<style lang="scss" scoped>

@import "./scss/pc.scss";
@import "./scss/m.scss";
</style>