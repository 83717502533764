<template>
  <div id="product">
    <heading title="产品" subtitle="product"></heading>
    <swiper :options="swiperOption">
      <swiper-slide>
        <div class="m-item">
          <img src="~img/product/zhibo.png" alt="" srcset="" />
          <div class="desc">直播</div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="m-item">
          <img src="~img/product/dsp.png" alt="" srcset="" />
          <div class="desc">短视频</div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="m-item">
          <img src="~img/product/yuyin.png" alt="" srcset="" />
          <div class="desc">语音聊天室</div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="m-item">
          <img src="~img/product/zaixianshangcheng.png" alt="" srcset="" />
          <div class="desc">在线商城小程序</div>
        </div>
      </swiper-slide>

      <swiper-slide>
        <div class="m-item">
          <img src="~img/product/wuye.png" alt="" srcset="" />
          <div class="desc">物业管理APP</div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="m-item">
          <img src="~img/product/fxsb.png" alt="" srcset="" />
          <div class="desc">风行社保小程序</div>
        </div>
      </swiper-slide>

      <swiper-slide>
        <div class="m-item">
          <img src="~img/product/cdz.png" alt="" srcset="" />
          <div class="desc">充电桩小程序</div>
        </div>
      </swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
  </div>
</template>

<script>
export default {
  data() {
    return {
      swiperOption: {
        loop: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        autoplay: {
          delay: 1500,
          stopOnLastSlide: false,
          disableOnInteraction: true,
        },
      },
    };
  },
  // components: {
  //   swiper,
  //   swiperSlide
  // }
};
</script>

<style lang='scss' scoped>
.m-item {
  text-align: center;
  img {
    width: 4.32rem;
    height: 8.47rem;
  }
  .desc {
    font-size: 0.36rem;
    font-weight: bold;
    margin-top: 0.9rem;
    margin-bottom: 1.17rem;
  }
}

.swiper-pagination {
  ::v-deep .swiper-pagination-bullet {
    background-color: transparent;
    border: 2px solid #000;
    width: 0.15rem;
    height: 0.15rem;

    &.swiper-pagination-bullet-active {
      background-color: #e35959;
      border-color: #e35959;
    }
  }
}
</style>

