<template>
  <div id="header">
    <div class="w">

        <!--  class="logo" -->
        <!-- <img src="~img/header/logo.png" alt="" /> -->
        <!-- <h1 ></h1> -->
        <div class="company-name">
        <h1 class="chinese-name">武汉市基言科技有限公司</h1>
        <h1 class="english-name">Wuhan BeWords Technology Co., Ltd.</h1>
      </div>
      <nav class="nav">
        <div class="title">
          <i @click="togle" class="iconfont icon-caret-down"></i>
        </div>
        <ul>
          <li class="active"><a href="#technology">技术优势</a></li>
          <li><a href="#product">产品</a></li>
          <li><a href="#service">服务</a></li>
          <!-- <li><a href="#team">团队</a></li> -->
          <li class="last"><a href="#about-us">关于我们</a></li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
export default {
  name: "FxHeader",
  mounted() {
    var isEvent = false;
    var t = new Date();
    $("#header .nav a").click(function (e) {
      e.preventDefault();
      if (new Date() - 2500 < t) return;
      isEvent = true;
      t = new Date();
      $(this).parent().addClass("active").siblings().removeClass("active");
      var href = $(this).attr("href");
      var h = $("#header").height();
      history.replaceState(null, null, href);
      $("html").animate({ scrollTop: $(href).offset().top - h }, 1500, () => {
        isEvent = false;
      });
    });
    window.addEventListener("scroll", () => {
      var h = $(document).scrollTop();
      if (isEvent) return;
      // 是否找到元素
      var isFind = false;
      $("#header .nav a").each((i, el) => {
        if (isFind) return;
        var href = $(el).attr("href");
        if ($(href).offset().top - $("#header").height() > h) {
          isFind = true;
          $(el).parent().addClass("active").siblings().removeClass("active");
        }
      });
    });
  },
  methods: {
    togle(event) {
      if ($(event.target).hasClass("icon-caret-down")) {
        $(event.target)
          .removeClass("icon-caret-down")
          .addClass("icon-arrow_up_fat")
          .parent()
          .next()
          .addClass("show");
      } else {
        $(event.target)
          .removeClass("icon-arrow_up_fat")
          .addClass("icon-caret-down")
          .parent()
          .next()
          .removeClass("show");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
h1 {
  color: #fff; /* 或者 color: white; */
  margin: 0; /* 移除默认内边距 */
}

.chinese-name {
  /* 可选: 添加中文名称的样式 */
}

.english-name {
  /* 可选: 添加英文名称的样式 */
}
@import "./scss/pc.scss";
@import "./scss/m.scss";
</style>