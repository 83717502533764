<template>
  <div id="team">
    <div class="w">
      <heading title="团队" subtitle="team"></heading>

      <!-- 轮播图的主体容器 -->
      <Swiper>
        <!-- 产品经理 -->
        <swiper-item>
          <div class="team-slide-box">
            <div class="avarat">
              <img src="~img/team/person1.png" alt="" srcset="" />
            </div>
            <div class="office">产品经理</div>
          </div>
        </swiper-item>
        <!-- UI设计师 -->
        <swiper-item>
          <div class="team-slide-box">
            <div class="avarat">
              <img src="~img/team/person2.png" alt="" srcset="" />
            </div>

            <div class="office">UI设计师</div>
          </div>
        </swiper-item>
        <!-- 技术总监 -->
        <swiper-item>
          <div class="team-slide-box">
            <div class="avarat">
              <img src="~img/team/person3.png" alt="" srcset="" />
            </div>

            <div class="office">技术总监</div>
          </div>
        </swiper-item>

        <!-- 前端开发工程师 -->
        <swiper-item>
          <div class="team-slide-box">
            <div class="avarat">
              <img src="~img/team/person4.png" alt="" srcset="" />
            </div>

            <div class="office">前端开发工程师</div>
          </div>
        </swiper-item>

        <!-- 后端开发工程师 -->
        <swiper-item>
          <div class="team-slide-box">
            <div class="avarat">
              <img src="~img/team/person5.png" alt="" srcset="" />
            </div>

            <div class="office">后端开发工程师</div>
          </div>
        </swiper-item>
      </Swiper>
    </div>
  </div>
</template>

<script>
import SwiperItem from "./components/SwiperItem";
import Swiper from "./components/Swiper";
export default {
  name: "FxTeam",
  components: {
    Swiper,
    SwiperItem,
  },
};
</script>

<style lang="scss" scoped>
#team {
  ::v-deep .section-title::after {
    left: 0.25rem;
  }   
}

.team-slide-box {
  .avarat {
    img {
      width: 2.4rem;
      height: 3.8rem;
    }
  }
  .office {
    margin-top: 0.63rem;
    text-align: center;
    font-size: 0.2rem;
  }
}
</style>