import Vue from 'vue'
import App from './App.vue'

import "./assets/css/normalize.css"
import "./assets/js/setRem"

// 引入全局组件自动加载注册
import './components/index'

Vue.config.productionTip = false

import VueAwesomeSwiper from 'vue-awesome-swiper'

import 'swiper/swiper-bundle.css'
Vue.use(VueAwesomeSwiper, /* { default options with global component } */)
new Vue({
  render: h => h(App),
}).$mount('#app')
