<template>
  <div id="product">
    <!-- 产品只有标题部分在版心内 -->
    <div class="w">
      <heading title="产品" subtitle="product"></heading>
    </div>
    <div class="product-box">
      <div class="product-box-m">
        <div class="m-item">
          <img src="~img/product/zhibo.png" alt="" srcset="" />
          <div class="desc">直播</div>
        </div>
        <div class="m-item">
          <img src="~img/product/dsp.png" alt="" srcset="" />
          <div class="desc">短视频</div>
        </div>
        <div class="m-item">
          <img src="~img/product/yuyin.png" alt="" srcset="" />
          <div class="desc">语音聊天室</div>
        </div>
        <div class="m-item">
          <img src="~img/product/zaixianshangcheng.png" alt="" srcset="" />
          <div class="desc">在线商城小程序</div>
        </div>
        <div class="m-item">
          <img src="~img/product/wuye.png" alt="" srcset="" />
          <div class="desc">物业管理APP</div>
        </div>
        <div class="m-item">
          <img src="~img/product/fxsb.png" alt="" srcset="" />
          <div class="desc">风行社保小程序</div>
        </div>
        <div class="m-item">
          <img src="~img/product/cdz.png" alt="" srcset="" />
          <div class="desc">充电桩小程序</div>
        </div>
      </div>
      <div class="product-box-pc">
        <div class="title">数据大屏</div>
        <div class="pc-ls">
          <div class="pc-item">
            <img src="~img/product/pc_home.png" alt="" />
            <div class="desc">首页展示</div>
          </div>
          <div class="pc-item">
            <img src="~img/product/data_page.png" alt="" />
            <div class="desc">数据页面展示</div>
          </div>
          <div class="pc-item">
            <img src="~img/product/data_page2.png" alt="" />
            <div class="desc">数据页面展示</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
export default {
  name: "FxProduct",
  mounted() {
    $("#product .product-box").hover(
      // 滑入时触发
      function () {
        $(".product-box-m")
          .stop()
          .fadeOut(2000, function () {
            $(".product-box-pc").stop().fadeIn(2000);
          });
      },
      // 滑出时触发
      function () {
        $(".product-box-pc")
          .stop()
          .fadeOut(2000, function () {
            $(".product-box-m").stop().fadeIn(2000);
          });
      }
    );
  },
};
</script>

<style lang="scss" scoped>
#product {
  height: 10rem;
  background: url("~img/product/bg.png");
  background-size: cover;
  // 标题部分
  ::v-deep .section-title::after {
    left: 0.29rem;
  }

  .product-box {
    position: relative;
    height: 4rem;
    color: #6c6e85;
    // 移动端
    .product-box-m {
      position: absolute;
      left: 0;
      top: 0;
      display: flex;
      justify-content: center;
      width: 100%;
      .m-item {
        width: 11.51%;
        margin: 0 0.94%;
        img {
          width: 100%;
        }

        .desc {
          margin-top: 0.2rem;
          font-size: 0.18rem;
          text-align: center;
        }
      }
    }

    // PC端
    .product-box-pc {
      position: absolute;
      left: 0;
      top: 0;
      display: none;
      .title {
        font-size: 0.36rem;
        text-align: center;
        margin-bottom: 0.54rem;
      }

      .pc-ls {
        display: flex;
        justify-content: center;

        .pc-item {
          width: 30.05%;
          margin: 0 0.95%;
          img {
            width: 100%;
          }

          .desc {
            margin-top: 0.57rem;
            font-size: 0.18rem;
            text-align: center;
          }
        }
      }
    }
  }
}
</style>