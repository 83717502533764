<template>
  <div class="swiper-container">
    <div class="swiper-wrapper" ref="swiper">
      <slot></slot>
    </div>
    <!-- 上一张 -->
    <div class="swiper-button-prev" @click="handlePrev"></div>
    <!-- 下一张 -->
    <div class="swiper-button-next" @click="handleNext"></div>
  </div>
</template>

<script>
import $ from "jquery";
export default {
  name: "Swiper",
  data: function () {
    return {
      startTime: new Date(),
      slideCount: 0,
      slideRenderCount: 0,
      currentIndex: 0,
      slideWidth: 0,
      $swiper: null,
    };
  },
  methods: {
    // 渲染元素，解决
    handleDom: function () {
      this.$swiper = $(this.$refs.swiper);
      this.currentIndex = this.slideCount = this.$swiper.find(
        ".swiper-slide"
      ).length;
      var HtmlTemplate = this.$swiper.html();
      this.$swiper.append(HtmlTemplate).append(HtmlTemplate);

      this.slideRenderCount = this.$swiper.find(".swiper-slide").length;

      this.slideWidth = this.$swiper.find(".swiper-slide").outerWidth(true);

      this.$swiper.css("left", this.slideWidth * -this.currentIndex);
    },

    handleNext() {
      var currentTime = new Date();
      // 如果距离上一次点击没有一秒中的时间差，就不执行操作
      if (currentTime - this.startTime < 1100) return;
      // 如果超过一秒钟，那么就重置 开始时间
      this.startTime = currentTime;

      if (this.currentIndex + this.slideCount == this.slideRenderCount - 1) {
        this.currentIndex = this.slideCount - 1;
        this.$swiper.css("left", -this.currentIndex * this.slideWidth);
      }
      this.currentIndex++;
      this.$swiper
        .stop()
        .animate({ left: -this.currentIndex * this.slideWidth }, 1000);
    },
    handlePrev() {
      var currentTime = new Date();
      // 如果距离上一次点击没有一秒中的时间差，就不执行操作
      if (currentTime - this.startTime < 1100) return;
      // 如果超过一秒钟，那么就重置 开始时间
      this.startTime = currentTime;
      if (this.slideCount == this.currentIndex) {
        this.currentIndex = 2 * this.slideCount;
        this.$swiper.css("left", -this.currentIndex * this.slideWidth);
      }
      this.currentIndex--;
      this.$swiper
        .stop()
        .animate({ left: -this.currentIndex * this.slideWidth }, 1000);
    },
  },
  mounted: function () {
    //1.操作dom，在前后添加slide
    setTimeout(() => {
      this.handleDom();
    }, 200);
  },
  // watch: {
  //   currentIndex(currentIndex, old) {
  //     console.log({ currentIndex, old });
  //   },
  // },
};
</script>

<style lang="scss" scoped>
.swiper-container {
  position: relative;
  width: 11.4rem;
  padding-left: 0.9rem;
  padding-right: 0.9rem;
  margin: 0.5rem auto;

  .swiper-wrapper {
    height: 5rem;
    position: relative;
  }

  .swiper-button-prev {
    position: absolute;
    top: 0;
    left: -0.01rem;
    width: 0.9rem;
    height: 110%;
    background-color: #fff;

    &:after {
      content: "";
      width: 0.5rem;
      height: 0.4rem;
      background-image: url("../../../assets/img/team/button-prev.png");
      background-size: cover;
    }
  }

  .swiper-button-next {
    position: absolute;
    top: 0;
    right: 0;
    width: 0.9rem;
    height: 110%;
    background-color: #fff;
    &:after {
      content: "";
      width: 0.5rem;
      height: 0.4rem;
      background-image: url("../../../assets/img/team/button-next.png");
      background-size: cover;
    }
  }
}

@media screen and (max-width:750px) {
  .swiper-container {
    width: 5rem;
  }
}
</style>

