<template>
  <section id="swiper">
    <div class="w">
      <!-- 左边的类似于标题的 -->
      <div class="left">
        <div class="title_zh">
          <!-- <img src="@/assets/img/swiper/title_zh.png" alt="" /> -->
        </div>
        <!-- <p class="title_en">FENGXINGCHUANGXIANGKEJI</p> -->
        <div class="title_en_pic">
          <!-- <img src="@/assets/img/swiper/title_en.png" alt="" /> -->
        </div>
      </div>
      <!-- 右边的文本 -->
      <div class="right">
        <!-- 更多 -->
        <div class="more">
          <a href="#">
            <span>更多</span>
            <img src="@/assets/img/swiper/right_arrow.png" alt="" />
          </a>
        </div>
        <h2>优质的技术团队</h2>
        <p>移动端领域多端技术</p>
        <p>源码服务解决商</p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "FxSwiper",
};
</script>

<style lang="scss" scoped>
@import "./scss/pc.scss";
@import "./scss/m.scss";
</style>