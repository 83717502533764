<template>
  <div id="footer">
    <div class="w">
      <!-- 这个是底部，下面的是备案信息 -->
      <div class="foot clearfix">
        <div class="top">
          <!-- 咨询电话 -->
          <!-- <div class="contact-phone clearfix">
            <div class="tel_icon"></div>
            <div class="tel">
              <div class="tel_label">咨询电话</div>
              <div class="telphone">13260621018</div>
            </div>
          </div> -->
          <!-- 二维码 -->
          <!-- <div class="qr-code">
            <img src="~img/footer/qr-code.jpg" alt="" />
          </div> -->
        </div>
        <!-- 邮箱、QQ、地址等信息 -->
        <div class="contact">
          <p>邮箱：asd486@live.com </p>
          <p>
            地址：湖北省武汉市东湖新技术开发区关东园路2-2号光谷国际商会大厦A座1703室
          </p>

          <p>武汉市基言科技有限公司 </p>
          <p>Wuhan BeWords Technology Co., Ltd. @copyright</p>
        </div>
      </div>
      <section class="copyright-section">
        <!-- <p>友情链接：<a href="https://shebaogou.com">风行社保</a></p> -->
        <p>
          <a href="https://beian.miit.gov.cn" target="_blank">
            备案号：鄂ICP备2020018976号-7
          </a>
        </p>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "FxFoot",
};

</script>

<style lang="scss" scoped>
@import "./scss/pc.scss";
@import "./scss/m.scss";

// .company-name {
//   position: fixed;
//   top: 20px;
//   left: 20px;
//   font-size: 18px;
//   font-weight: bold;
// }


// .chinese-name {
//   /* 可选: 添加中文名称的样式 */
// }

// .english-name {
//   /* 可选: 添加英文名称的样式 */
// }
</style>