<template>
  <div id="service">
    <div class="w">
      <heading title="服务" subtitle="service"></heading>
      <!-- 轮播图 -->
      <swiper ref="mySwiper" :options="swiperOptions" class="service-swiper">
        <!-- APP定制开发 -->
        <swiper-slide>
          <div class="left">
            <div class="title">
              <img src="~img/service/APP.png" alt="" />
              <span>APP定制开发</span>
            </div>
            <div class="reviews-container">
              <div class="review" done="100" title="短视频"></div>
              <div class="review" done="100" title="语音聊天室"></div>
              <div class="review" done="100" title="直播"></div>
              <div class="review" done="100" title="小程序"></div>
              <div class="review" done="100" title="物业管理APP"></div>
            </div>
          </div>
          <div class="right">
            <div class="app">
              <img src="~img/service/fxsbapp.png" alt="" />
            </div>
            <div class="qr-code">
              <img src="~img/service/fx-code.png" alt="" />
              <h3>风行社保小程序</h3>
              <p>打开微信扫码【风行社保小程序】</p>
            </div>
          </div>
        </swiper-slide>

        <!-- 短视频 -->
        <swiper-slide>
          <div class="left">
            <div class="title">
              <img src="~img/service/duanshipin.png" alt="" />
              <span>短视频</span>
            </div>
            <div class="reviews-container">
              <div class="review" done="100" title="社交创意"></div>
              <div class="review" done="100" title="采集拍摄"></div>
              <div class="review" done="100" title="剪裁拼接"></div>
              <div class="review" done="100" title="特效制作"></div>
            </div>
          </div>
          <div class="right">
            <div class="app">
              <img src="~img/service/dspapp.png" alt="" />
            </div>
            <div class="m-app">
              <h3>短视频app</h3>
              <p>
                <img src="~img/service/icon1.png" alt="" />
                <a
                  href="https://apps.apple.com/cn/app/95%E7%A7%80-%E6%89%8B%E6%9C%BA%E7%9B%B4%E6%92%AD%E8%A7%86%E9%A2%91%E4%BA%A4%E5%8F%8B/id840756414"
                  target="_blank"
                  >跳转到App Store</a
                >
              </p>
            </div>
          </div>
        </swiper-slide>

        <!-- 语音聊天室 -->
        <swiper-slide>
          <div class="left">
            <div class="title">
              <img src="~img/service/ic_voice_chat_px.png" alt="" />
              <!-- <span>语音聊天室</span> -->
              <span style="vertical-align: top">语音聊天室</span>
            </div>
            <div class="reviews-container">
              <div class="review" done="100" title="clubhouse"></div>
              <div class="review" done="100" title="对话吧"></div>
              <div class="review" done="100" title="米聊类定制"></div>
            </div>
          </div>
          <div class="right">
            <div class="app">
              <img src="~img/service/ltsapp.png" alt="" />
            </div>
            <div class="m-app">
              <h3>语音聊天app</h3>
              <p>
                <img src="~img/service/icon1.png" alt="" />
                <a href="https://apps.apple.com/cn/app/%E4%B8%8A%E9%BA%A6-%E4%BC%A0%E9%80%92%E6%99%BA%E6%85%A7/id1549493884" target="_blank"
                  >跳转到App Store</a
                >
              </p>
            </div>
          </div>
        </swiper-slide>

        <!-- 直播 -->
        <swiper-slide>
          <div class="left">
            <div class="title">
              <img src="~img/service/zhibo.png" alt="" />
              <span>直播</span>
            </div>
            <div class="reviews-container">
              <div class="review" done="100" title="电商导购"></div>
              <div class="review" done="100" title="互动秀场"></div>
              <div class="review" done="100" title="在线教育"></div>
            </div>
          </div>
          <div class="right">
            <div class="app">
              <img src="~img/service/zhiboapp.png" alt="" />
            </div>
            <div class="m-app">
              <h3>电商直播平台</h3>
              <p>
                <img src="~img/service/icon1.png" alt="" />
                <a
                  href="https://apps.apple.com/cn/app/95%E7%A7%80-%E6%89%8B%E6%9C%BA%E7%9B%B4%E6%92%AD%E8%A7%86%E9%A2%91%E4%BA%A4%E5%8F%8B/id840756414"
                  target="_blank"
                  >跳转到App Store</a
                >
              </p>
            </div>
          </div>
        </swiper-slide>

        <!-- 小程序 -->
        <swiper-slide>
          <div class="left">
            <div class="title">
              <img src="~img/service/xiaochengxu.png" alt="" />
              <span>小程序</span>
            </div>
            <div class="reviews-container">
              <div class="review" done="100" title="小程序商城"></div>
              <div class="review" done="100" title="物业小程序"></div>
              <div class="review" done="100" title="在线授课小程序"></div>
            </div>
          </div>
          <div class="right">
            <div class="app">
              <img src="~img/service/cdzxcx.png" alt="充电桩小程序" />
            </div>
            <div class="m-app">
              <h3>充电桩小程序</h3>
              <p>
                <img src="~img/service/icon1.png" alt="" />
                <a href="https://www.baidu.com/" target="_blank"
                  >跳转到App Store</a
                >
              </p>
            </div>
          </div>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
export default {
  name: "FxService",
  data() {
    return {
      swiperOptions: {
        loop: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        on: {
          slideChange() {
            var swiper = this.slides[this.activeIndex];
            $(swiper)
              .find(".review")
              .each((i, el) => {
                var done = Number.parseInt($(el).attr("done")) || 0;
                done = done > 100 ? 100 : done;
                var title = $(el).attr("title");
                var html = `
            <div class="progress"><div class="progress-done"></div></div>
            <span class="percent">${done}%</span><span class="title">${title}</span>
            `;
                $(el).html(html);

                $(el)
                  .find(".progress-done")
                  .css("width", 0)
                  .stop()
                  .animate(
                    {
                      width: done + "%",
                    },
                    2000,
                    "swing"
                  );
              });
          },
        },
      },
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    },
  },
  mounted() {
    // console.log("Current Swiper instance object", this.swiper);
    // 组件形式的，第一次不触发回调，让他移动两次，回到第一页就了
    this.swiper.slideTo(0, 0, true);
    this.swiper.slideTo(1, 0, true);
  },
};
</script>
<style lang="sass" scoped>
@import "./scss/pc.scss"
@import "./scss/m.scss"
</style>