<template>
  <section id="about-us">
    <!-- 左边的 -->
    <div class="left">
      <div class="us-box">
        <!-- 标题 -->
        <heading title="关于我们" subtitle="about us"></heading>
        <div class="us-swiper">
          <div class="swiper-list">
            <div class="swiper-item">
              <h3>公司简介</h3>
              <div class="content">
                <p>
                  武汉市基言科技有限公司武汉有限公司位于武汉高新技术开发区光谷国际商会大厦，是在高新技术应用领域中专业从事网络科技、计算机科技、信息科技、直播、短视频、语音聊天室、电子商务平台领域内的软件开发、技术转让、技术咨询和技术服务。
                </p>

                <p>
                  我们倡导“专业、务实、高效、创新”的企业精神，具有良好的内部机制。优良的工作环境以及良好的激励机制，吸引了一批年轻的、有学识的、具有实干精神的人才。高素质、高水平、高效率的人才是我们在当今激烈的市场中立于不败之地的保障。
                </p>
              </div>
            </div>
            <div class="swiper-item">
              <h3>业务简介</h3>
              <div class="content">
                <p>我们可以解决您：</p>
                <p>
                  系统集成、应用系统开发，小程序定制开发，公众号企业微信开发，软硬件整合开发
                </p>

                <p>
                  与国内外知名IT厂商建立紧密的合作关系，与业内知名的IT服务供应商建立的战略伙伴合作关系可以让您时刻把握前沿的技术脉搏和行业动态，这一切，我们愿与您共享。武汉市基言科技有限公司武汉有限公司真诚希望能够有机会参与您的企业信息化建设，解决您在信息建设过程中出现的各类的问题，为您信息化建设做出我们的贡献。
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="swiper-btn">
          <i class="prev-btn"></i>
          <i class="next-btn"></i>
        </div>
      </div>
    </div>
    <!-- 右边的 -->
    <div class="right">
      <div class="list">
        <div class="list-item">
          <div class="icon">
            <img src="~img/aboutus/sheji.png" alt="" />
          </div>
          <div class="area">
            <div class="title">——安全、完整的系统策划和设计</div>
            <div class="text">
              公司在产品研发方面的高投入，众多项目的实际应用，让我们具备了相应的的创造力和丰富的经验，这将成为您解决疑问和难题的良好保证。可为您提供多方面完善的策划：如项目的规划立项、总体方案设计、方案评估论证。
            </div>
          </div>
        </div>

        <div class="list-item">
          <div class="icon">
            <img src="~img/aboutus/jishu.png" alt="" />
          </div>

          <div class="area">
            <div class="title">——先进、专业的技术支持</div>
            <div class="text">
              公司众多一流人才的深层磨合，对最新技术执拗的探讨精神，使我们能够保证为你提供最专业的应用，最专业的服务。
            </div>
          </div>
        </div>
        <div class="list-item">
          <div class="icon">
            <img src="~img/aboutus/shouhou.png" alt="" />
          </div>
          <div class="area">
            <div class="title">——完善、快速的售后服务</div>
            <div class="text">
              以最快的速度、最有效的方法、最先进的技术保障系统的效果发挥到极至，解除您的后顾之忧。
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import $ from "jquery";
export default {
  name: "FxAbout",
  mounted() {
    var index = 1;
    var length = $("#about-us").find(".swiper-item").length;
    $("#about-us")
      .find(".prev-btn")
      .click(function () {
        if (--index < 1) {
          index = length;
        }
        var width = $("#about-us").find(".swiper-item").outerWidth(true);
        $(".swiper-list").css("margin-left", width * -(index - 1));
      });

    $("#about-us")
      .find(".next-btn")
      .click(function () {
        if (++index > length) {
          index = 1;
        }
        var width = $("#about-us").find(".swiper-item").outerWidth(true);
        $(".swiper-list").css("margin-left", width * -(index - 1));
      });
  },
};
</script>

<style lang="scss" scoped>
@import "./scss/pc.scss";
@import "./scss/m.scss";
</style>