<template>
  <a class="gotop" href="javascript:;">
    <i class="iconfont icon-up"></i>
  </a>
</template>

<script>
import $ from "jquery"
export default {
  name: "FxGoTop",
  mounted() {
    $(".gotop").hide();
    $(window).bind("scroll", function () {
      if ($(window).scrollTop() <= 300) {
        $(".gotop").hide();
      } else {
        $(".gotop").show();
      }
    });
    $(".gotop").bind("click", function () {
      $("html, body").animate({ scrollTop: 0 }, 2500);
    });
  },
};
</script>

<style lang="scss" scoped>
.gotop {
  position: fixed;
  right: 0.3rem;
  bottom: 1rem;
  width: 0.45rem;
  height: 0.45rem;
  background-color: rgba(62, 62, 62, 1);
  text-align: center;
  color: #fff;
}
.gotop i {
  font-size: 0.4rem;
  line-height: 0.45rem;
}
</style>