<template>
  <div id="app">
    <!-- 头部LOGO 和 导航 -->
    <fx-header></fx-header>
    <!-- 头部的 banner -->
    <fx-swiper></fx-swiper>
    <!-- 技术优势 -->
    <fx-technology></fx-technology>
    <!-- 产品 -->
    <fx-product v-if="isPC"></fx-product>
    <!-- 产品移动端 -->
    <fx-product-m v-else></fx-product-m>
    <!-- 服务 -->
    <fx-service></fx-service>
    <!-- 团队 -->
    <!-- <fx-team></fx-team> -->
    <!-- 关于我们 -->
    <fx-about></fx-about>
    <!-- 底部 -->
    <fx-foot></fx-foot>
    <!-- 回到顶部 -->
    <!-- <fx-go-top></fx-go-top> -->
  </div>
</template>

<script>
import FxHeader from "@/components/FxHeader";
import FxSwiper from "@/components/FxSwiper";
import FxTechnology from "@/components/FxTechnology";
import FxProduct from "@/components/FxProduct";
import FxProductM from "@/components/FxProductM";
import FxService from "@/components/FxService";
import FxTeam from "@/components/FxTeam";
import FxAbout from "@/components/FxAbout";
import FxFoot from "@/components/FxFoot";
import FxGoTop from "./components/FxGoTop";
export default {
  name: "App",
  components: {
    FxHeader,
    FxSwiper,
    FxTechnology,
    FxProduct,
    FxProductM,
    FxService,
    FxTeam,
    FxAbout,
    FxFoot,
    FxGoTop,
  },
  computed:{
    isPC(){
      return document.body.clientWidth > 750
    }
  }
};
</script>