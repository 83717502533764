<template>
  <div class="section-heading">
    <div class="section-title">{{ title }}</div>
    <div class="section-subtitle">{{ subtitle }}</div>
  </div>
</template>

<script>
export default {
  name: "Heading",
  props: {
    title: {
      type: String,
      default: "",
    },
    subtitle: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
/* 每个模块的标题部分， */
.section-heading {
  padding-top: 1px;
  margin-bottom: 1.8rem;

  .section-title {
    margin-top: 1.74rem;
    position: relative;
    font-size: 0.24rem;
    font-weight: bold;
    color: #000000;
    opacity: 0.8;

    &::after {
      position: absolute;
      content: "";
      top: -0.55rem;
      left: 0.35rem;
      width: 2.4rem;
      height: 1.5rem;
      border: 0.1rem solid rgba(121, 137, 154, 0.1);
    }
  }

  .section-subtitle {
    margin-top: 0.22rem;
    text-transform: uppercase;
    font-size: 0.18rem;
    font-weight: bold;
    color: #000000;
    opacity: 0.4;
  }
}

@media screen and (max-width: 750px) {
  .section-heading {
    margin-left: 50%;
    transform: translateX(-40%);
  }
}
</style>