<template>
  <div class="swiper-slide">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "Slide",
};
</script>

<style scoped>
.swiper-slide {
  float: left;
  width: 3.2rem;
  padding: 0 0.4rem;
  color: #6c6e85;
}
</style>

